import React, {useEffect, useState} from 'react'
import {restDelete, restGet, restPost, restPut} from "../../utils/RestUtils";
import NavBar from "../nav-bar/NavBar";
import {addSingleErrorToScreen, getServerSideErrorMessage} from "../../Validation/ErrorValidation";
import {getLoginItem} from "../../utils/LoginUtils";
import TargetsView from "./TargetsView";
import {logJSON} from "../../utils/FormattingUtils";
import TargetUpdatePopUp from "./TargetUpdatePopUp";
import TargetCreatePopUp from "./TargetCreatePopUp";


const TargetsWrapper = () => {

    const [usersTargets, setUsersTargets] = useState([]); // all the lists for this user

    const [targetToEdit, setNewTargetToEdit] = useState(null);
    const [newFatPercent, setNewFatPercent] = useState();
    const [newCarbPercent, setNewCarbsPercent] = useState();
    const [newProteinPercent, setNewProteinPercent] = useState();
    const [profileName, setProfileName] = useState();// used only for new ptofiles... not for updates

    const [targetToCreatePopup, setTargetToCreatePopup] = useState(false);


    const [errorMessageData, setErrorMessageData] = useState([]);


    const onRemoveTargetProfile = async (listToDitchId) => {


        let justDeletedId = await restDelete(`/fmd/target-profile/${listToDitchId}`); // no need to await, it's async
        doFirst()
    }


    const openTargetCreatePopUp = async () => {

        setTargetToCreatePopup(true) // setting this should open popup
    }

    const openTargetUpdatePopUp = async (targetToEdit) => {

        let targetCopy = JSON.parse(JSON.stringify(targetToEdit));

        setNewTargetToEdit(targetCopy) // setting this should open popup
    }


    const sortTargetsCorrectly = (scrambledTargets) => {

        scrambledTargets = scrambledTargets.sort(function (a, b) {
            return b.dateCreated - a.dateCreated
        });

        return scrambledTargets;
    }

    const fetchTargetsForThisUser = async (userId) => {

        let responseJson = await restGet(`/fmd/registeredusers/${userId}/target-profiles/`);
        let errorText = getServerSideErrorMessage(responseJson);

        if (errorText != null) {
            await addSingleErrorToScreen(responseJson.messageCode, errorText, setErrorMessageData) // hard coded for error in same position regardless of the error
        } else {

            responseJson.targetProfilesList = sortTargetsCorrectly(responseJson.targetProfilesList)
            await setUsersTargets(responseJson)
        }
    }

    const doFirst = async () => {

        let regUser = getLoginItem("re");
        await fetchTargetsForThisUser(regUser)

    }

    const closePopUp = () => {
        console.log("closepop");
        setNewTargetToEdit(null)
        setTargetToCreatePopup(false)
    }


    const updateTarget = async (profName, fatVal, proVal, carbVal) => { // don't store these in state due to refreshing issue in draggable pie chart
console.log("profName in method:" + profName)

        let fatIndex
        let proIndex
        let carbIndex
        for (let i = 0; i < targetToEdit.targetNutrientProportionsList.length; i++) {
            let nutId = targetToEdit.targetNutrientProportionsList[i].nutrientDto.nutrientId
            switch (nutId) {
                case 10:
                    fatIndex = i
                    break
                case 20:
                    proIndex = i
                    break
                case 30:
                    carbIndex = i
                    break
                default:
            }

        }
        let targetProfileDto = targetToEdit;
        //   const oldPieEl = document.getElementById(targetProfileDto.profileName);
        //  oldPieEl.remove(); // needed or pie chart will not refresh.. don't know why

        // console.log("profName " + profName)
        targetProfileDto.profileName = profName
        targetProfileDto.isDefault = false // not implemented yet
        targetProfileDto.registeredUserDto = {"registeredUserId": getLoginItem("re")}
        targetProfileDto.targetNutrientProportionsList[fatIndex].percentage = fatVal
        targetProfileDto.targetNutrientProportionsList[proIndex].percentage = proVal
        targetProfileDto.targetNutrientProportionsList[carbIndex].percentage = carbVal

        console.log("ATTEMPTING A SAVE OF:\n" + logJSON(targetProfileDto))

        console.log("target profile to update is = " + JSON.stringify(targetProfileDto))

        let saveObj = await restPut("/fmd/target-profile", {"targetProfileDto": targetProfileDto});

        await doFirst();
        await closePopUp();

    }


    const createTarget = async (fatVal, proVal, carbVal) => { // don't store these in state due to refreshing issue in draggable pie chart

        console.log("ATTEMPTING A SAVE OF:\n" + logJSON(targetToEdit))

        let targetProfileDto = {
            targetProfileDto: {
                "targetProfileId": null,
                "registeredUserDto": {"registeredUserId": getLoginItem("re")},
                "profileName": profileName.profileName,
                "isDefault": false,
                "targetNutrientProportionsList": [
                    {
                        "targetNutrientProportionId": null,// we want it added
                        "nutrientDto": {"nutrientId": 10},
                        "percentage": fatVal
                    },
                    {
                        "targetNutrientProportionId": null,
                        "nutrientDto": {"nutrientId": 20},
                        "percentage": proVal
                    },
                    {
                        "targetNutrientProportionId": null,
                        "nutrientDto": {"nutrientId": 30},
                        "percentage": carbVal
                    }
                ],
            }
        }

        console.log("target profile to create is = " + JSON.stringify(targetProfileDto))


        let saveObj = await restPost("/fmd/target-profile", targetProfileDto);


        closePopUp();

        await doFirst();

    }

    const getPercentFromPropList = (nutDbId, nutPropList) => {
        // order of these from the database can't be guaranteed
        for (let i = 0; i < nutPropList.length; i++) {
            if (nutDbId === nutPropList[i].nutrientDto.nutrientId) {
                return nutPropList[i].percentage;
            }
        }
    }

    useEffect(() => {
        doFirst();
    }, []);

    return (<>

        {targetToEdit != null ?
            <TargetUpdatePopUp
                targetToEdit={targetToEdit}
                setFat={setNewFatPercent}
                setProtein={setNewProteinPercent}
                setCarbs={setNewCarbsPercent}
                updateTarget={updateTarget}
                closePopUp={closePopUp}
                getPercentFromPropList={getPercentFromPropList}
                errorMessageData={errorMessageData}
            /> : null}

        {targetToCreatePopup === true ?
            <TargetCreatePopUp
                saveTarget={createTarget}
                closePopUp={closePopUp}
            /> : null}

        <NavBar/>
        <TargetsView
            usersTargets={usersTargets}
            onRemoveTargetProfile={onRemoveTargetProfile}
            openTargetCreatePopUp={openTargetCreatePopUp}
            openTargetUpdatePopUp={openTargetUpdatePopUp}
            setNewTargetName={setProfileName}
            getPercentFromPropList={getPercentFromPropList}

            errorMessageData={errorMessageData}
        />
        {
            JSON.stringify(targetToEdit)
        }
        <br/>
    </>);
};

export default TargetsWrapper;
